<template>
  <ValidationObserver
    ref="form"
    v-slot="{ passes }"
    tag="section"
    class="min-h-screen pt-1 pb-16 bg-gray-100"
  >
    <van-notice-bar
      left-icon="volume-o"
      text="您所填写的内容将由物业考核小组进行核实并选取典型问题匿名发布在考核大厅，请您如实填写。"
    />
    <van-cell-group title="联系人信息">
      <ValidationProvider
        v-slot="{ errors }"
        vid="name"
        name="姓名"
        mode="lazy"
        rules="min:2|max:20"
        slim
      >
        <van-field
          v-model="issue.name"
          clearable
          placeholder="请输入您的姓名"
          label="姓名"
          :error="errors.length > 0"
          :error-message="errors[0]"
        />
      </ValidationProvider>

      <ValidationProvider
        v-slot="{ errors }"
        vid="mobile"
        name="手机号码"
        mode="lazy"
        rules="required|phone"
        slim
      >
        <van-field
          v-model="issue.mobile"
          required
          clearable
          placeholder="请输入您的手机号码以便联系"
          autocomplete="off"
          type="tel"
          label="手机号码"
          :error="errors.length > 0"
          :error-message="errors[0]"
        />
      </ValidationProvider>

      <ValidationProvider
        v-slot="{ errors }"
        vid="unit"
        name="楼号及单元"
        mode="lazy"
        rules="required"
        slim
      >
        <van-field
          v-model="issue.unit"
          readonly
          clearable
          required
          label="楼号及单元"
          placeholder="请选择楼号及单元"
          :error="errors.length > 0"
          :error-message="errors[0]"
          @click="showUnitPicker = true"
        />
      </ValidationProvider>
      <ValidationProvider
        v-slot="{ errors }"
        vid="room"
        name="房号"
        mode="lazy"
        rules="min:2|max:50"
        slim
      >
        <van-field
          v-model="issue.room"
          clearable
          placeholder="请输入您的房号，例如：901"
          autocomplete="off"
          label="房号"
          :error="errors.length > 0"
          :error-message="errors[0]"
        />
      </ValidationProvider>
    </van-cell-group>
    <van-cell-group
      title="考核内容"
      class="mt-3"
    >
      <ValidationProvider
        v-slot="{ errors }"
        vid="rootCategory"
        name="类别"
        mode="lazy"
        rules="required"
        slim
      >
        <van-field
          v-model="issue.rootCategory"
          required
          readonly
          clearable
          placeholder="请选择考核类别"
          autocomplete="off"
          label="类别"
          :error="errors.length > 0"
          :error-message="errors[0]"
          @click="showRootCategoryPicker = true"
        />
      </ValidationProvider>

      <ValidationProvider
        v-if="issue.rootCategory"
        v-slot="{ errors }"
        vid="subCategory"
        name="子类别"
        mode="lazy"
        rules="required"
        slim
      >
        <van-field
          v-model="issue.subCategory"
          required
          readonly
          clearable
          placeholder="请选择子类别"
          autocomplete="off"
          label="子类别"
          :error="errors.length > 0"
          :error-message="errors[0]"
          @click="showSubCategoryPicker = true"
        />
      </ValidationProvider>

      <ValidationProvider
        v-if="issue.subCategory"
        v-slot="{ errors }"
        vid="item"
        name="条款"
        mode="lazy"
        rules="required"
        slim
      >
        <div class="van-cell van-cell--required van-field">
          <div class="van-cell__title van-field__label">
            <span>条款</span>
          </div>
          <div class="van-cell__value van-field__value">
            <van-radio-group
              v-model="issue.item"
            >
              <van-radio
                v-for="item of items"
                :key="item"
                class="my-2 text-left"
                :name="item"
              >
                <template #default>
                  <span
                    :class="{ 'text-red-500': errors.length > 0 }"
                  >{{
                    item
                  }}</span>
                </template>
              </van-radio>
            </van-radio-group>
            <div
              v-if="errors.length > 0"
              class="van-field__error-message"
            >
              {{ errors[0] }}
            </div>
          </div>
        </div>
      </ValidationProvider>

      <ValidationProvider
        v-slot="{ errors }"
        vid="issuedAt"
        name="事发日期"
        mode="lazy"
        rules="required"
        slim
      >
        <van-field
          v-model="issue.issuedAt"
          readonly
          clickable
          clearable
          required
          placeholder="请输入事发日期"
          autocomplete="off"
          label="事发日期"
          :right-icon="issue.issuedAt ? 'close' : ''"
          :error="errors.length > 0"
          :error-message="errors[0]"
          @click="showComplaintPicker = true"
          @click-right-icon.prevent.stop="issue.issuedAt = null"
        />
      </ValidationProvider>

      <ValidationProvider
        v-slot="{ errors }"
        vid="title"
        name="内容"
        mode="lazy"
        rules="required|min:2|max:1000"
        slim
      >
        <van-field
          v-model="issue.content"
          clearable
          required
          autosize
          rows="2"
          type="textarea"
          placeholder="请输入您发现的问题"
          autocomplete="off"
          label="内容"
          maxlength="1000"
          show-word-limit
          :error="errors.length > 0"
          :error-message="errors[0]"
        />
      </ValidationProvider>
      <div class="px-3 my-3 font-weight-bold">
        相关照片
      </div>
      <van-uploader
        v-model="issue.attachments"
        class="mx-3"
        multiple
        :max-count="9"
        :before-read="beforeRead"
      />
      <div class="px-3 pb-3 text-xs text-gray-300">
        最多上传 9 张照片
      </div>
    </van-cell-group>

    <div class="w-full px-4 mt-6 mb-8">
      <van-button
        type="danger"
        block
        round
        :loading="processing"
        loading-text="发送中..."
        @click="passes(onSubmit)"
      >
        立即发送
      </van-button>
    </div>
    <van-popup
      v-model="showUnitPicker"
      round
      position="bottom"
      :safe-area-inset-bottom="true"
    >
      <van-picker
        show-toolbar
        :columns="units"
        @cancel="showUnitPicker = false"
        @confirm="onUnitConfirm"
      />
    </van-popup>
    <van-popup
      v-model="showRootCategoryPicker"
      round
      position="bottom"
      :safe-area-inset-bottom="true"
    >
      <van-picker
        show-toolbar
        :columns="rootCategories"
        @cancel="showRootCategoryPicker = false"
        @confirm="onRootCategoryConfirm"
      />
    </van-popup>
    <van-popup
      v-model="showSubCategoryPicker"
      round
      position="bottom"
      :safe-area-inset-bottom="true"
    >
      <van-picker
        show-toolbar
        :columns="subCategories"
        @cancel="showSubCategoryPicker = false"
        @confirm="onSubCategoryConfirm"
      />
    </van-popup>
    <van-calendar
      v-model="showComplaintPicker"
      first-day-of-week="1"
      type="single"
      :min-date="$dayjs().subtract(6, 'month').toDate()"
      :max-date="new Date()"
      @confirm="onComplaintConfirm"
    />
  </ValidationObserver>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import images from '@/mixins/uploader/images'
import share from '@/mixins/share'
export default {
  name: 'PublicServiceAuditEdit',
  mixins: [images, share],
  data() {
    return {
      loading: true,
      processing: false,
      showUnitPicker: false,
      showRootCategoryPicker: false,
      showSubCategoryPicker: false,
      showComplaintPicker: false,
      issue: {
        title: null,
        name: null,
        mobile: null,
        unit: null,
        room: null,
        content: null,
        issuedAt: this.$dayjs().format('YYYY-MM-DD'),
        rootCategory: null,
        subCategory: null,
        item: null,
        attachments: []
      }
    }
  },
  computed: {
    ...mapState('Common/Cloud', ['tcb']),
    ...mapGetters('Common/Credential', ['userInfo']),
    ...mapState('Common/Config', ['units']),
    ...mapState('Common/Standard', ['standards']),
    rootCategories() {
      return this.standards.map(item => item.name)
    },
    subCategories() {
      if (!this.issue?.rootCategory) {
        return []
      }
      return (
        this.standards.find(item => item.name === this.issue.rootCategory)
          ?.categories ?? []
      ).map(item => item.name)
    },
    items() {
      if (!this.issue?.rootCategory || !this.issue?.subCategory) {
        return []
      }
      return (
        (
          this.standards.find(item => item.name === this.issue.rootCategory)
            ?.categories ?? []
        ).find(item => item.name === this.issue.subCategory)?.items ?? []
      )
    }
  },
  mounted() {
    this.initialize()
    this.disableShareMenu()
  },
  methods: {
    initialize() {
      if (this.userInfo?.status && this.userInfo.status !== '未认证') {
        this.issue.name = this.userInfo.name
        this.issue.mobile = this.userInfo.mobile
        this.issue.unit = this.userInfo.unit
        this.issue.room = this.userInfo.room
      }
    },
    onUnitConfirm(values) {
      this.issue.unit = values[0] == '无' ? null : values.join('-')
      this.showUnitPicker = false
    },
    onRootCategoryConfirm(value) {
      if (value != this.issue.rootCategory) {
        this.issue.rootCategory = value
        this.issue.subCategory = null
        this.issue.item = null
      }
      this.showRootCategoryPicker = false
    },
    onSubCategoryConfirm(value) {
      if (value != this.issue.subCategory) {
        this.issue.subCategory = value
        this.issue.item = null
      }
      this.showSubCategoryPicker = false
    },
    onComplaintConfirm(value) {
      this.issue.issuedAt = this.$dayjs(value).format('YYYY-MM-DD')
      this.showComplaintPicker = false
    },
    onBeforeSubmit() {
      return new Promise((resolve, reject) => {
        this.$dialog
          .confirm({
            title: '注意事项',
            message: '您填写的物业考核相关内容将由物业考核小组进行审核，如果内容不符合要求，将会被驳回。请您确认您填写的内容无误，是否继续？',
            theme: 'round-button',
            showCancelButton: true,
            cancelButtonText: '继续完善',
            confirmButtonText: '确认无误'
          })
          .then(() => {
            resolve()
          }).catch(() => {
            reject()
          })
      })
    },
    async onSubmit() {
      try {
        await this.onBeforeSubmit()
      } catch(e) {
        return
      }
      this.processing = true
      const data = {
        $url: 'subscriberPost',
        userInfo: this.userInfo,
        issue: this.issue
      }
      // eslint-disable-next-line no-console
      console.warn('onSubmit', JSON.stringify(data))
      this.tcb
        .callFunction({
          name: 'property_service',
          data
        })
        .then(res => {
          // eslint-disable-next-line no-console
          console.log(res)
          let authenticated = ['已认证', '待审核'].includes(
            this.userInfo?.status
          )
          this.$dialog
            .confirm({
              title: '发送成功',
              message: authenticated
                ? '您的物业考核相关内容已成功发送，感谢您的宝贵意见！'
                : '您尚未进行业主认证，认证后填写物业考核相关内容会更方便哦。是否立即进行业主认证？',
              theme: 'round-button',
              showCancelButton: authenticated ? false : true,
              cancelButtonText: '返回列表',
              confirmButtonText: authenticated ? '返回列表' : '业主认证'
            })
            .then(() => {
              if (authenticated) {
                this.$router.replace({
                  name: 'public.service.audit.home'
                })
              } else {
                this.$router.push({
                  name: 'public.authentication'
                })
              }
            })
            .catch(() => {
              this.$router.replace({
                name: 'public.service.audit.home'
              })
            })
        })
        .catch(e => {
          // eslint-disable-next-line no-console
          console.error(e)
          this.$notify({
            type: 'danger',
            message: '发送失败，由于微信服务异常，暂时无法发送'
          })
        })
        .finally(() => {
          this.processing = false
        })
    }
  }
}
</script>

<style></style>
